import React from 'react';
import contents from './content.js'
// import Cards from './cards'
import Cards from '../Cards/cards'
export const Services =()=>(
  
        <div id="services"> 
            <h2>What We Offer</h2>
              <Cards contents={contents}/>
        </div>
  
    
)


