const navLink = [
    {
        title : 'Home', 
        path : '/#home',
        activeState : 'active'
    }, 
    {
        title : 'About', 
        path : '/#about'
    }, 
    {
        title : 'Services', 
        path : '/#services'
        
    },
    {
        title : 'Rooms',
        path : '/rooms'
    }

]
export default navLink;

