import React from 'react'
import {Container} from 'react-bootstrap';




export const About= () =>(
  
        
        
        <div id="about" className="about">
            <Container >
                <h2>Mintewab Lodge in Gondar</h2>
                <p>
                    Mintewab Lodge, a three-star resort hotel, offers breathtaking scenery, magnificent sunrises, and a commanding view of the royal city of Gondar, built in medieval times. Known as the Camelot of Africa, Gondar is one of the leading tourist destinations in Ethiopia, endowed with natural, cultural and historical resources, and famous for its world heritage sites registered by UNESCO. Among these are the imperial enclosure (the fortress-city known as Fasil Ghebbi, including Emperor Fasil's bathing pool), ancient monasteries, and the Simien Mountains National Park with its exotic  plants, animals, and birds, some of which are unique to the region (Walia Ibex, Simien Jackal, Gelada Baboon, Lammergeyer, and many others.
                </p>
                <p>
                    Mintewab Lodge sits atop an 80-meter elevation and can be reached on foot by trekking about 120 meters from the main asphalt road, or in a vehicle by driving the 350-meter gravel road after driving about 1 km asphalt road from Gondar University/Maraki Square to the Direction of Gondar University Hospital (Chechela).
                </p>
            </Container>
        </div>
        
    
    
    
)
