import SingleBedroom from "../../assets/card/rooms/singleBedroom.jpg";
import DoubleBedroom from "../../assets/card/rooms/doubleBedroom.jpg";
import TwinBedroom from "../../assets/card/rooms/twinBedroom.jpg";
const rooms = [
  {
    roomType: "Single Bedroom",
    Description:
      "Our Single Room offers a comfortable and spacious retreat for couples or solo travelers. Relax in a room featuring a luxurious king-sized bed, modern amenities, and breathtaking views of the surrounding natural beauty.",

    imgsrc: SingleBedroom,
  },
  {
    roomType: "Family Suite",
    Description:
      " The Family Suite is designed to accommodate small families or groups. Unwind in this spacious suite, which includes a king-sized bed and an additional single bed. Enjoy the beautiful views and make the most of your time together.",

    imgsrc: DoubleBedroom,
  },
  {
    roomType: "Twin Bedroom",
    Description:
      "Perfect for friends or family traveling together, our Twin Room provides a cozy stay. This room features two comfortable beds, ensuring a restful night's sleep for each guest. Enjoy the serene vistas and create memorable experiences together.",

    imgsrc: TwinBedroom,
  },
];

export default rooms;
