const testmonys =[
    {
        title : "Home Away from Home ",
        description : "My partner and I stayed here for a whole month while doing some work with the university, and we absolutely LOVED it! The location and scenery were absolutely breath-taking (we couldn't get over this stunning beauty every single morning as we woke up to the clouds on the mountaintop) and the staff really made us feel like part of the family. ",
        author : "Ingri-ingri"
    },
    {
        title : "Lovely hotel, but no sleep! ",
        description : "This is a beautiful hilltop setting for this lovely hotel. Individual rooms all with views. Clean and excellent service. Not an owner issue but we did not sleep all night as local chanting from church continues through the night on Saturdays. This may have been the same anywhere in Gondar on a Saturday",
        author : "julie m"
    },
]

export default testmonys