import Bedroom from "../../assets/card/service/bedroom.jpg";
import Sunset from "../../assets/card/service/sunset.jpg";
import People from "../../assets/card/service/people.jpg";

const contents = [
  {
    title: "Comfortable Accommodation",
    description:
      "Enjoy a relaxing stay in our cozy lodge rooms, complete with plush bedding and modern amenities.",
    image: Bedroom,
  },
  {
    title: "Scenic Location",
    description:
      "Nestled in nature, our lodge offers a picturesque setting with stunning views of forests, the city of Gondar and mountains. ",
    image: Sunset,
  },
  {
    title: "Event Facilities",
    description:
      "Host your special occasions or corporate retreats in our versatile event spaces, accommodating both small and large gatherings.",
    image: People,
  },
];

export default contents;
